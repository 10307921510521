import { useState, useEffect, useContext } from 'react'

import { Language } from '../context/language'
import { Box, Button, Container, Typography, Divider } from '@mui/material'


import './other.css'

const About = ({}) => {
  const { language } = useContext(Language)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main className="other">
      <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: "row", my: "80px", justifyContent: "space-between", flexWrap: "wrap" }}>
        <h1><Typography variant="h1">{language.about.header}</Typography></h1>
        <Box className="item">
          <h2><Typography variant="h4">{language.about.title1}</Typography></h2>
          <p><Typography variant="p">{language.about.blurb1}</Typography></p>
          <p><Typography variant="p">{language.about.blurb2}</Typography></p>
        </Box>
        <Box className="item">
          <h2><Typography variant="h4">{language.about.title2}</Typography></h2>
          <p><Typography variant="p">{language.about.blurb3}</Typography></p>
        </Box>
        <Box className="item">
          <h2><Typography variant="h4">{language.about.title3}</Typography></h2>
          <p><Typography variant="p">{language.about.blurb4}</Typography></p>
        </Box>
        <Box className="item">
          <h2><Typography variant="h4">{language.about.title4}</Typography></h2>
          <p><Typography variant="p">{language.about.blurb5}</Typography></p>
        </Box>
        <Box className="item" sx={{ mt: 10 }}>
          <Typography variant="h3">{language.about.about_pit}</Typography>
        </Box>
      </Container>
    </main>
  );
}

export default About;
