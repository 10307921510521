import { createTheme } from '@mui/material/styles'

let theme = createTheme({
  typography: {
    fontFamily: ['Gotham A', 'Gotham B'].join(","),
  },

  shape: { borderRadius: "2px" },

  palette: {
    mode: 'light',
    
    primary: {
      main: "#0897e9", // blue
      light: "#f5f8ff",
      dark: "#000654",
      contrastText: "#ffffff"
    },
    
    secondary: {
      main: "#FF3860", // red
      light: "#ff99ad",
      contrastText: "#ffffff"
    },

    background: {
      default: "#ecf2ff",
    },
    
    borders: {
      light: "#c4c5ce", dark: ""
    },

    warning: {
      main: '#FD8421',
      light: '#FFA270',
      contrastText: '#FFFFFF',
    },
  },
  time: {
    delay: (n) => n,
    duration: (n) => n,
  },
})

theme.typography.button = {
  fontSize: "1.1rem",
  textTransform: "capitalize",
  fontWeight: "600"
}

theme.typography.h1 = {
  fontSize: "60px",
  textTransform: "capitalize",
  fontWeight: "300",
  color: "#000d29",
  fontFamily: ['Gotham A', 'Gotham B'].join(",")
}
theme.typography.h2 = {
  textTransform: "capitalize",
  fontWeight: "500",
  color: "#000d29",
  fontFamily: ['Gotham A', 'Gotham B'].join(",")
}
theme.typography.h3 = {
  fontSize: "24px",
  textTransform: "capitalize",
  fontWeight: "400",
  color: "#000d29",
  fontFamily: ['Gotham A', 'Gotham B'].join(",")
}
theme.typography.h4 = {
  fontSize: "1.5rem",
  textTransform: "capitalize",
  fontWeight: "600"
}

theme = createTheme(theme, {
  palette: {
    header: theme.palette.augmentColor({
      color: {
        main: '#f5f8ff',
        contrastText: "#000d29"
      },
      name: 'header',
    }),
  },
});

theme = createTheme(theme, {
  palette: {
    background: theme.palette.augmentColor({
      color: {
        main: '#ecf2ff',
        contrastText: "#000d29"
      },
      name: 'background',
    }),
  },
});

theme = createTheme(theme, {
  palette: {
    nav: theme.palette.augmentColor({
      color: {
        main: '#0000008a',
        contrastText: "#000d29"
      },
      name: 'nav',
    }),
  },
});

export { theme }