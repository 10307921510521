import './logo.css'

import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import anime from 'animejs/lib/anime.es.js'

const Logo = ({}) => {
	let navigate = useNavigate()

	useEffect(() => {
	    anime
	      .timeline({
	        easing: 'easeInOutSine',
	      })
	      .add({
	        targets: '#logocard',
	        duration: 2000,
	        points: [
	          {
	            value:
	              '38.018 28.046 47.564 11.704 30.065 1.482 15.981 25.593 33.893 35.769 38.018 28.046',
	          },
	          {
	            value:
	              '38.018 36.372 47.564 20.029 30.065 9.808 15.981 33.918 20.182 36.372 38.018 36.372',
	          },
	        ],
	      })
	  }, [])
	
	const logoHover = () => {
		const up = anime
			.timeline({
			easing: 'easeInOutSine',
			loop: false,
			})
			.add({
			targets: '#logocard',
			duration: 800,
			points: [
			  {
			    value:
			      '38.018 28.046 47.564 11.704 30.065 1.482 15.981 25.593 33.893 35.769 38.018 28.046',
			  },
			  {
			    value:
			      '38.018 36.372 47.564 20.029 30.065 9.808 15.981 33.918 20.182 36.372 38.018 36.372',
			  },
			],
		})
  	}

	return (
		<div className='logo'
			sx={{ mr: 1 }}
			onMouseEnter={() => logoHover()}
			onClick={() => window.location = "https://voteflare.org"} >
            <svg className='logo' viewBox='0 0 56.277 71.993'>
              <g>
                <path
                  className='logoa'
                  d='M54.988,44.924,45.745,29.646a2,2,0,0,0-1.722-.965l-29.742.156a2,2,0,0,0-1.6.809L1.394,44.871A2,2,0,0,0,1,46.063v22.93a2,2,0,0,0,2,2H53.277a2,2,0,0,0,2-2V45.96A2,2,0,0,0,54.988,44.924Z'
                />
                <line
                  className='logob'
                  x1='40.675'
                  y1='36.372'
                  x2='16.675'
                  y2='36.372'
                />
                <polygon
                  id='logocard'
                  className='logoa'
                  points='38.018 36.372 47.564 20.029 30.065 9.808 15.981 33.918 20.182 36.372 38.018 36.372'
                />

                <line
                  className='logoa'
                  x1='55.277'
                  y1='45.402'
                  x2='1'
                  y2='45.402'
                />
                <path
                  className='logoc'
                  d='M15.981,0c1.962,5.394,4.414,7.846,9.808,9.808-5.394,1.961-7.846,4.413-9.808,9.807-1.961-5.394-4.413-7.846-9.807-9.807C11.568,7.846,14.02,5.394,15.981,0Z'
                />
                <path
                  className='logoc'
                  d='M41.68,18.626a5.373,5.373,0,0,0,1.495,5.061,5.373,5.373,0,0,0-5.061,1.495,5.373,5.373,0,0,0-1.495-5.061A5.373,5.373,0,0,0,41.68,18.626Z'
                />
              </g>
            </svg>
            <div className='logo-text' sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
              Vote
              <b className='logo-bold'>
                Flare
              </b>
              <sup className='trademark'>TM</sup>
            </div>
    </div>
	)
}

export default Logo