import { useState, useEffect, useContext } from 'react';
import { Language } from '../context/language'
import { HistoryContent } from '../context/history'

import Img1 from './imgs/dec-2020-01.png'
import Img2 from './imgs/dec-2020-02.png'
import Img3 from './imgs/dec-2020-03.png'
import Img4 from './imgs/aug-2020-01.png'
import Img5 from './imgs/feb-2020-01.png'
import Img6 from './imgs/nov-2016-01.png'
import Img7 from './imgs/mar-2022-01.png'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';



import './other.css'

const History = ({ page }) => {

	const { language } = useContext(Language);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const content = [{
			year: 2022,
			month: "March",
			title: "VoteFlare at the 2022 Texas Primary",
			blurb: <p>Harvard's Public Interest Tech Lab made VoteFlare available to voters in Texas free of charge during the Texas Primary. Team members (alphabetically): Bradley Abruzzi, Pascal Delpe-Brice, Jordan Buchman, Eli Munn, Josh Shank, Latanya Sweeney, and Josh Visnaw (Project Manager). The work was featured in <a href='https://www.govtech.com/security/securing-the-midterms-smarter-tools-watch-over-voter-records'>GovTech</a></p>,
			image_urls: [Img7]
		},{
			year: 2020,
			month: "December",
			title: "VoteFlare at the Georgia Runoff Election",
			blurb: (<p>Latanya Sweeney led a team that developed and deployed a production grade version of VoteFlare using the <a href='https://mydatacan.org'>MyDataCan</a> infrastructure. The effort was part of Harvard&rsquo;s public interest tech initiative and was free to Georgia voters during the runoff Senatorial election. Professor Sweeney&rsquo;s team members included Bradley Abruzzi, Pascal Delpe-Brice, Brad Frank, Eli Munn and Jinyan Zang. Georgia voters signed up at <a href='https://voteflare.org/'>https://voteflare.org</a> and the service monitored their voter registrations in real-time and sent text or email messages if any issue arose that needed the voter&rsquo;s attention. Recent Harvard graduate, Dhruv Gupta, and Harvard student, Sumhith Aradhyula, developed accompanying technology to offer free rides to the polls, schedulable by text message, to those users who opted to vote on election day. VoteFlare&trade; performed tens of thousands of lookups flawlessly. Voters said the system relieved a lot of voter anxiety and the team received many thank you messages from Georgia voters afterwards. The work was first featured in <a href='https://www.axios.com/vote-flare-notification-registration-georgia-harvard-ad18a192-4329-4714-88d4-0292336a835c.html'>Axios</a> and the <a href='https://www.thecrimson.com/article/2020/12/24/harvard-students-voting-technology/'>Harvard Crimson</a>"</p>),
			image_urls: [Img1, Img2, Img3]
		},{
			year: 2020,
			month: "August",
			title: "VoteFlare Prototypes",
			blurb: <p>Jinyan Zang and Daniel Gottesman developed a prototype that demonstrated usability and user interaction. Latanya Sweeney developed and demonstrated technology back-end prototypes that worked for each of the websites for 48 states and the District of Columbia.</p>,
			image_urls: [Img4]
		},{
			year: 2020,
			month: "February",
			title: "Gov1430 Explores the VoteFlare Concept",
			blurb: <p>Professor Latanya Sweeney challenged students in her Gov1430 Tech Science to Save the World course at Harvard to explore the viability and utility of an automated service that monitors voter registrations for voters. Jinyan Zang was the teaching fellow for the course and helped develop concepts. Students demonstrated whether technology could achieve this goal in 48 states and the District of Columbia. Students (listed alphabetically) were Ryan Chung, Diego Garcia, Kaitlyn Greta, Juan Guzman, Pernilla Hamren, Jasmine Hyppolite, Aidan Keenan, Samuel Lurye, Paul Marino, Colin McGinn, David Netter, Kiera O’Brien, Jordy Rodriguez, Bruna Saraiva and Alyx Van Der Vorm.</p>,
		  image_urls: [Img5]
		},{
			year: 2016,
			month: "November",
			title: "Vulnerabilities found in Voter Websites",
			blurb: <p>Latanya Sweeney, Jinyan Zang and JiSu Yoo, in the Data Privacy Lab at Harvard University, led a study that found voters could be impersonated at state websites. Da&rsquo;Von Boyd, Evelyn Chen, Asia DaCosta, and Rachel Matheson (listed alphabetically) were the 2016 Technology Science summer interns who worked with them and who identified some of the state websites. Natalie Altman, Evelyn Chen, and Anupama Jeevan (listed alphabetically) provided help with automation.&nbsp;The study found:<br /><ul><li>Websites for 35 states and DC in 2016 were vulnerable to voter identity theft attacks: animposter could submit changes to voter registration information</li><li>An imposter needed a combination of voter&rsquo;s name, date of birth, gender, address, Social Security Number, or Driver&rsquo;s License Number</li><li>Relevant data can be acquired from government, data brokers, or darknet markets. Total cost of an automated attack against 1% of all vulnerable voter registrations nationwide ranged from $10,081 to $24,926 depending on the data source used. States cost less, e.g., $1 for Alaska and $1,020 for Illinois</li></ul>A voter identity theft attack could disrupt an election by imposters submitting address changes, deleting voter registrations, or requesting absentee ballots. (<a href='https://techscience.org/a/2017090601'>published paper</a>)</p>,
		  image_urls: [Img6]
	}]
		
	return (
		<main className="other">
			<Container maxWidth="xl" sx={{ display: 'flex', flexDirection: "row", mb: "80px", justifyContent: "space-between", flexWrap: "wrap" }}>
				{content.map((e, index) => (
					<Box className="big-item" sx={{ display: "flex" }}>
						<Box sx={{ flexGrow: 1, flexWrap: "nowrap" }} ><h2><Typography variant="Button" color="nav.main">{e.month} {e.year}</Typography></h2></Box>
						<Box sx={{ maxWidth: 2/3 }}>
							<h1 >{e.title}</h1>
							<p sx={{ pl: 10 }}>{e.blurb}</p>
							{e.image_urls.map((i) => (
								<Box sx={{ display: "flex", flexDirection: "row" }}>
									<img sx={{ maxWidth: "300px", m: 3 }} src={i} />
								</Box>
							))}
						</Box>
					</Box>
				))}
			</Container>
		</main>
	);
}

export default History;
