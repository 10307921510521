import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import { render } from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'

import Partner from './home/partner'
import FAQs from './other/faqs'
import Header from './header/header'
import About from './other/about'
import History from './other/history'
import Terms from './other/terms'
import Lookup from './forms/main/lookup'
import Portal from './portal/portal'
import axios from './tools/axiosWrapper'

import DashboardInfo from './other/dashboard_info'
// import Dashboard from './dashboard/dashboard'
import { Charts } from './tools/charts'
import { theme } from './theme'

import { Language } from './context/language'
import { languages } from './context/languages'

const App = () => {
	const [language, set_language] = React.useState(languages[0])
	const [auth, setAuth] = React.useState(false)

	const value = React.useMemo(
		() => ({ language, set_language }),
		[language, set_language]
	)

	React.useEffect(() => {
		axios.get('/auth/me').then((res) => {
			const { data } = res
			setAuth(data.id ? 1 : 0)
		})
	}, [])

	return (
		<React.Fragment>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<Language.Provider value={value}>
						<Routes>
							<Route path='/' element={<Header auth={auth} />}>
								<Route index element={<Partner />} />
								<Route path='/about' element={<About />} />
								<Route path='/terms' element={<Terms />} />
								<Route path='/history' element={<History />} />
								<Route path='/faqs' element={<FAQs />} />
								<Route path='/dashboard' element={<DashboardInfo />} />
							</Route>
							{/* <Route path='/dashboard' element={<Dashboard />} /> */}
						</Routes>
					</Language.Provider>
				</BrowserRouter>
			</ThemeProvider>
		</React.Fragment>
	)
}

export default App
