import './partner.css'

import { useState, useEffect, useContext } from 'react'

import { Container, Box, Button, Link, Typography } from '@mui/material';
import { Language } from '../context/language'
import { ContactForm } from '../header/header'
import flare from '../media/flare_mockup.png'

const Partner = ({ }) => {
	function useWindowSize() {
		const [windowSize, setWindowSize] = useState({
			width: undefined,
			height: undefined,
		})
		useEffect(() => {
			function handleResize() {
				setWindowSize({
					width: window.innerWidth,
				})
			}
			window.addEventListener('resize', handleResize)
			handleResize()
			return () => window.removeEventListener('resize', handleResize)
		}, [])
		return windowSize.width > 700 ? 0 : 1
	}

	const mobile = useWindowSize()

	const { language, set_language } = useContext(Language)

	return (
		<main class="partner">
			<Container maxWidth="xl" sx={{ display: 'flex', flexDirection: "row", my: "80px", justifyContent: "space-between", flexWrap: "wrap" }}>
				<Box sx={{ flexDirection: "column", maxWidth: 2/3 }}>
					<h1><Typography variant="h2">
						Join the VoteFlare<sup className='trademark'>TM</sup> Association Program
					</Typography></h1>
					<h3>{language.partner.blurb}</h3>
					<Box>
						<Button variant="contained" size="large" sx={{ my: 10, mr: 3 }}
							href={ContactForm} target="_blank">
								<Typography variant="button">{language.partner.cta}</Typography>
						</Button>
						<Link href="/faqs/#membership" color="primary">
							<Typography variant="button">{language.partner.learn}</Typography>
						</Link>
					</Box>
				</Box>
				<Box sx={{ maxWidth: 1/3, minWidth: "287px" }}>
					<img className="flare" src={flare} id='flare' />
				</Box>
			</Container>
		</main>
	)
}

export default Partner
