import { useState, useEffect, useContext } from 'react'

import ReactPlayer from 'react-player'
import { Language } from '../context/language'
import { Box, Button, Container, Typography, Divider, Grid } from '@mui/material'

import screenStatewide from '../media/dashboard_statewide.jpg';
import screenCounty from '../media/dashboard_county.jpg';

const DashboardInfo = ({ }) => {
	return (
		<main className="other">
			<Container maxWidth="xl" sx={{ display: 'flex', flexDirection: "row", my: "80px", justifyContent: "space-between", flexWrap: "wrap" }}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Box className="item">
							<h1><Typography variant="h1">Dashboard</Typography></h1>

							<p>
								<Typography variant="p">
									Each association includes a personalized VoteFlare™ dashboard, where you can track critical changes to users' voter records by monitoring VoteFlare™ alerts. The specifics of the service can be adapted to meet an organization's unique need, providing timely analytics so you can help cure issues before it's too late.
								</Typography>
							</p>
						</Box>
					</Grid>

					<Grid item xs>
						<Box className="item">
							<a href={screenStatewide} target='_blank' rel="noreferrer">
								<img style={{ width: "100%", maxWidth: "unset" }} src={screenStatewide} alt="Ohio Dashboard - Statewide" />
							</a>
							<br />
							<a href={screenStatewide} target='_blank' rel="noreferrer">
								<img style={{ width: "100%", maxWidth: "unset" }} src={screenCounty} alt="Ohio Dashboard - County View" />
							</a>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</main>
	)
}

export default DashboardInfo