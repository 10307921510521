import './header.css'
import { useState, useEffect, useContext } from 'react'
import { BrowserRouter, Routes, Route, Outlet, Link, useNavigate } from 'react-router-dom'

import { Language } from '../context/language'
import { History } from '../context/history'
import axios from '../tools/axiosWrapper'

import { Box, AppBar, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import Partner from '../home/partner'
import FAQs from '../other/faqs'

import StatefulLogin from '../components/stateful_login'
import LangChoice from '../components/language_choice'
import Logo from '../components/animated_logo'
import techlab from '../media/techlab.svg'
import newamerica from '../media/newamerica.svg'
import gov1430 from '../media/gov1430.png'
import mydatacan from '../media/mydatacan.png'
import dataprivacylab from '../media/dataprivacylab.png'

const ContactForm = 'https://docs.google.com/forms/d/e/1FAIpQLSeqxVoz8UwO6DYPvVeZWLrbMJTO9d19CzCP5G4Zy3l5bEasXg/viewform'

const Header = ({ auth }) => {
	let navigate = useNavigate()
	const { language, set_language } = useContext(Language)

	const pages = [{name: 'Dashboard', url: '/dashboard'}, {name: 'FAQs', url: "/faqs"}, {name: 'Contact', url: ContactForm}];

	const [anchorElNav, setAnchorElNav] = useState(null);
	const [anchorElUser, setAnchorElUser] = useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = (goTo) => {
		if (goTo) {
			window.location = goTo;
		}
		else {
			setAnchorElNav(null);
		}
	};

	return (
		<>
		<Container maxWidth={false} sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", minHeight: 1 }} disableGutters>
			<AppBar position="static" color={"header"}>
				<Container maxWidth="xl">
					<Toolbar disableGutters>
						<Box sx={{ flexGrow: 0, display: { xs: 'inline-flex', md: 'none' } }}>
							<IconButton
				              size="large"
				              aria-label="account of current user"
				              aria-controls="menu-appbar"
				              aria-haspopup="true"
				              onClick={handleOpenNavMenu}>
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: 'block', md: 'none' },
								}}
							>
								{pages.map((page, index) => (
									<MenuItem key={page.name} onClick={() => handleCloseNavMenu(page.url)} >
									  <Typography textAlign="center">
									  	{page.name}
									  </Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>

						<Logo sx={{ flexGrow: 0, display: { xs: 'none', md: 'inline-flex' }, mr: 1 }} />

						<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'inline-flex' }, justifyContent: "center" }}>
				            {pages.map((page) => (
				              <Button
				              	color={"nav"}
				                key={page.name}
				                onClick={() => handleCloseNavMenu(page.url)}
				                sx={{ mx: 4, display: 'block' }}>
				                	<Typography variant="h4">{page.name}</Typography>
				              </Button>
				            ))}
						</Box>
						<Box sx={{ flexGrow: 0, display: { xs: 'inline-flex', md: 'none' }}} />
						<Box sx={{ flexGrow: 1, ml: 1 }}>
							{/*<StatefulLogin auth={auth} />
							<LangChoice />*/}
						</Box>
					</Toolbar>
				</Container>
			</AppBar>

			<Outlet sx={{ flexGrow: 1 }} />

			<footer>
				<Container maxWidth="xl" sx={{ display: 'flex', justifyContent: "space-evenly", p: 4 }}>
					<Link to='about'>{language.header.about}</Link>
					<a href={ContactForm} target={'_blank'}>
						{language.header.contact}
					</a>
					<Link to='history'>{language.header.history}</Link>

					<Link to='terms'>{language.header.terms}</Link>
				</Container>
				<Container maxWidth="xl" 
					className="sponsors"
					sx={{ display: 'flex', justifyContent: "space-between", pt: 10, pb: 5 }}>
						<img src={techlab} id='techlab' />
						<img src={newamerica} id='newamerica' />
						<img src={gov1430} id='gov1430' />
						<img src={mydatacan} id='mydatacan' />
						<img src={dataprivacylab} id='dataprivacylab' />
				</Container>
			</footer>
		</Container>
		</>
	)
}

export { ContactForm }
export default Header

