import { useState, useEffect, useContext } from 'react'

import { Language } from '../context/language'
import { Box, Button, Container, Typography, Divider } from '@mui/material'

const FAQs = ({}) => {
	const { language } = useContext(Language)

	const general = [{
		question: "What is VoteFlare™",
		answer: "VoteFlare™ is a project of the Data Privacy Lab and Public Interest Tech Lab at Harvard University that provides an online voter registration monitoring service to members of the public.",
	},{
		question: "Why VoteFlare™",
		answer: "Whether it’s a new polling location due to redistricting, getting moved from active to inactive, or a complete removal from a voter roll, changes to voter records happen frequently. Since each state uses a different approach for list maintenance and voter outreach, timely communication and curing of issues present consistent challenges for election officials and voting populations they serve. VoteFlare™ utilizes machine learning to provide critical alerts when changes occur, direct-to-voter.",
	}]

	const membership = [{
		question: "What is a VoteFlare™ Associate?",
		answer: "VoteFlare™ offers unique services with timely direct-to-voter information flows to keep voters informed about changes made to their voter registrations and connect voters with others to cure problems. States, counties, or member organizations may acquire VoteFlare™ services for their voters and obtain a personalized dashboard to gain informational insights and track service results. Each association includes a customized service and a personalized dashboard. The specifics of the service depend on the organization’s needs.",
	},{
		question: "What kinds of Pilot Programs do Associations involve?",
		answer: "Most often, an organization is interested in providing the VoteFlare™ service free of charge to a set of voters or its members, and then using the dashboard to monitor aggregate, but demographically detailed, results.",
	},{
		question: "Here are some examples of VoteFlare™ Association Pilots",
		answer: "",
		bullets: [
			"A member organization makes the VoteFlare™ service available to its member through special activation links they send to its members. The link allows the member to quickly activate their VoteFlare™ account and begin the monitoring process. The organization’s dashboard allows the organization to communicate directly with its members to cure problems encountered on a timely basis.",
			"A county makes the VoteFlare™ service automatically available to all the voters in its county. When a change occurs to a voter’s registration, VoteFlare™ uses its contact information to proactively notify the voter using text messaging or email so the voter has the opportunity to make timely corrections.",
			"A state makes the VoteFlare™ service available to youth voters in the state because of the addresses of youth voters change frequently.  VoteFlare™ uses its own databases and ongoing direct-to-voter communications to help keep youth voters maintain their registrations over time."
		],
	},{
		question: "",
		answer: "For organizations that want to promote VoteFlare™ but don’t want a full suite association with a dashboard, an embed button and associate toolkit can be shared free of charge."
	},{
		question: "Is there a Fee paid for a VoteFlare™ Association?",
		bullets: [
			"Associations start with a $30,000 pilot project that covers the customization, setup and service delivery provided by VoteFlare™ specific to the needs of the organization for an election period.  Discounts are available for simple pilots. A pilot is a good way to begin a larger engagement with VoteFlare™ or to provide support and VoteFlare™ service to members of organizations."
		]
	}]

	const MembershipItem = ({ item }) => {

		let answer;
		if (item.answer) {
			answer = <p><Typography variant="p">{item.answer}</Typography></p>;
		}

		let bullets;
		if (item.bullets) {
			bullets = (
				<ul>
					{
						item.bullets.map((b, index) => {
							return <li key={index}><p><Typography variant="p">{b}</Typography></p></li>;
						})
					}
				</ul>
			)
		}

		return (
			<Box className="item">
				<h2><Typography variant="h3" color="nav.main">{item.question}</Typography></h2>

				{bullets}
				{answer}
			</Box>
		);
	};

	return (
		<main className="other">
			<Container maxWidth="xl" sx={{ display: 'flex', flexDirection: "row", my: "80px", justifyContent: "space-between", flexWrap: "wrap" }}>
				<h1><Typography variant="h1">Frequently Asked Questions</Typography></h1>
				{general.map((e, index) => (
					<Box key={index} className="item">
						<h2><Typography variant="h3" color="nav.main">{e.question}</Typography></h2>
						<p><Typography variant="p">{e.answer}</Typography></p>
					</Box>
				))}

				<Box sx={{ mb: "21px", width: "100%" }} />

				<h2><Typography variant="h1">Questions About the VoteFlare™ Association Program</Typography></h2>

				{membership.map((e, index) => <><MembershipItem key={index} item={e} /></>)}
			</Container>
		</main>
	)
}

export default FAQs